import React from 'react';
import Helmet from 'react-helmet';
import wrapInSection from '../components/wrapInSection';
import Image from '../components/Image';
import SignupMini, { signupFields } from '../components/SignupMini';
import wrapInLayout from '../components/wrapInLayout';

const CFTSpecialOffer = () => (
  <div className="page-wrapper">
    <Helmet title="КЭСПА – Специальное предложение для сотрудников ЦФТ (Центра Финансовых Технологий)" />
    <h1
      style={{
        marginTop: 0,
      }}
    >
      Специальное предложение <br /> для сотрудников{' '}
      <span
        style={{
          color: '#D82334',
        }}
      >
        ЦФТ (Центра Финансовых Технологий)
      </span>
    </h1>
    <div className="row">
      <div className="col-md-4 col-sm-offset-1">
        <p>
          Скидка{' '}
          <span
            style={{
              color: '#D82334',
              fontWeight: 'bold',
            }}
          >
            15%
          </span>{' '}
          на первую оплату любых занятий в школе английского языка КЭСПА.
        </p>

        <SignupMini
          signupFields={[
            ...signupFields,
            {
              name: 'whereFrom',
              label: '',
              type: 'hidden',
            },
          ]}
          initialValues={{
            lastName: '%',
            whereFrom:
              'Спец. предложение для ЦФТ (Центр Финансовых Технологий)',
          }}
        />
      </div>
      <div className="col-md-6 col-sm-offset-1">
        <Image
          src="kespa_sber_pic.png"
          alt="Специальное предложение для сотрудников ЦФТ (Центра Финансовых Технологий)!"
        />
      </div>
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(CFTSpecialOffer));
